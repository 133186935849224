<template>
  <v-dialog :value="true" max-width="600" scrollable persistent>
    <v-card :loading="loading">
      <v-toolbar color="green" dark
        ><v-toolbar-title>{{
          isNew ? "Q-Arbeit erfassen" : "Q-Arbeit bearbeiten"
        }}</v-toolbar-title></v-toolbar
      >

      <v-list two-line v-if="item">
        <v-list-item v-if="item.period">
          <v-list-item-avatar>
            <v-icon> mdi-calendar </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ item.period.description }}</v-list-item-title>
            <v-list-item-subtitle>{{
              formatDatespan(item.period.startDate, item.period.endDate)
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <PersonItemBasic :value="item.person" v-if="item.person" />

        <v-list-item>
          <v-list-item-avatar>
            <v-icon> mdi-instrument-triangle </v-icon>
          </v-list-item-avatar>

          <LookupValueInput
            v-model="item.instrument"
            :items="instruments"
            label="Instrument"
        /></v-list-item>
        <v-list-item
          ><v-textarea
            outlined
            v-model="item.description"
            label="Beschreibung"
            counter="2000"
          ></v-textarea
        ></v-list-item>
      </v-list>

      <v-divider />
      <v-card-actions
        ><v-btn text exact :to="{ name: 'QualityEdit' }">abbrechen</v-btn>
        <v-spacer />
        <v-btn text color="primary" @click="save" :loading="saving"
          >speichern</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { formatDatespan } from "common/utils/date.js";
import LookupValueInput from "common/components/LookupValueInput.vue";
import PersonItemBasic from "common/components/PersonItemBasic.vue";
export default {
  components: { LookupValueInput, PersonItemBasic },
  props: ["id"],
  data() {
    return {
      period: null,
      instruments: [],
      item: { description: "" },
      loading: false,
      saving: false,
    };
  },
  computed: {
    isNew() {
      return this.id == 0;
    },
  },
  methods: {
    formatDatespan,
    async fetchData() {
      this.loading = true;

      const instruments_all = await this.apiList({
        resource: "quality/instrument",
      });
      this.instruments = instruments_all
        .filter((el) => el.available)
        .sort((a, b) => a.code.localeCompare(b.code));
      this.period = await this.apiList({
        resource: "quality/period",
        query: "current",
      });
      if (this.isNew) {
        this.item = {
          person: this.$_profilePerson,
          period: this.period,
          instrument: null,
          description: "",
        };
      } else {
        this.item = await this.apiGet({
          resource: "quality/activity",
          id: this.id,
        });
      }
      this.loading = false;
    },

    async save() {
      this.saving = true;
      if (this.isNew) {
        await this.apiPost({
          resource: "quality/activity",
          data: this.item,
        });
      } else {
        await this.apiPut({
          resource: "quality/activity",
          data: this.item,
        });
      }

      this.saving = false;
      this.$root.showSuccess("Erfolgreich gespeichert");
      this.$router.push({ name: "QualityEdit" });
    },
  },
  async created() {
    this.fetchData();
  },
};
</script>
